import { hasPermissionGuard } from "../../core/guards";
import { Constants } from "../../shared/constants/constants";
import { PermissionAction } from "../../shared/constants/enums";

export const wfSubmenuPageGuards = [
  hasPermissionGuard({
    actor: {
      modifier: "and",
      permissions: [
        {
          permissionAction: PermissionAction.view,
          objectTypeId: Constants.CONSTANT_PERMISSIONS.WORKFLOW,
        },
      ],
    },
    redirectTo: "/403",
  }),
];

export const wfMonitoringPageGuards = [
  hasPermissionGuard({
    actor: {
      modifier: "and",
      permissions: [
        {
          permissionAction: PermissionAction.view,
          objectTypeId: Constants.CONSTANT_PERMISSIONS.WORKFLOW_MONITORING,
        },
      ],
    },
    redirectTo: "/403",
  }),
];

export const wfConfigurationViewPageGuards = [
  hasPermissionGuard({
    actor: {
      modifier: "and",
      permissions: [
        {
          permissionAction: PermissionAction.view,
          objectTypeId: Constants.CONSTANT_PERMISSIONS.WF_CONFIGURATION,
        },
      ],
    },
    redirectTo: "/403",
  }),
];

export const wfConfigurationCreatePageGuards = [
  hasPermissionGuard({
    actor: {
      modifier: "and",
      permissions: [
        {
          permissionAction: PermissionAction.create,
          objectTypeId: Constants.CONSTANT_PERMISSIONS.WF_CONFIGURATION,
        },
      ],
    },
    redirectTo: "/403",
  }),
];

export const wfConfigurationEditPageGuards = [
  hasPermissionGuard({
    actor: {
      modifier: "and",
      permissions: [
        {
          permissionAction: PermissionAction.manage,
          objectTypeId: Constants.CONSTANT_PERMISSIONS.WF_CONFIGURATION,
        },
      ],
    },
    redirectTo: "/403",
  }),
];

export const wfTriggerViewPageGuards = [
  hasPermissionGuard({
    actor: {
      modifier: "and",
      permissions: [
        {
          permissionAction: PermissionAction.view,
          objectTypeId: Constants.CONSTANT_PERMISSIONS.WF_TRIGGER,
        },
      ],
    },
    redirectTo: "/403",
  }),
];

export const wfTriggerCreatePageGuards = [
  hasPermissionGuard({
    actor: {
      modifier: "and",
      permissions: [
        {
          permissionAction: PermissionAction.create,
          objectTypeId: Constants.CONSTANT_PERMISSIONS.WF_TRIGGER,
        },
      ],
    },
    redirectTo: "/403",
  }),
];

export const wfTriggerEditPageGuards = [
  hasPermissionGuard({
    actor: {
      modifier: "and",
      permissions: [
        {
          permissionAction: PermissionAction.manage,
          objectTypeId: Constants.CONSTANT_PERMISSIONS.WF_TRIGGER,
        },
      ],
    },
    redirectTo: "/403",
  }),
];
